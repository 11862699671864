.optionPopup {
    width: 20vw;
    max-height: 50vh;
    display: grid;
    margin-left: 40vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 2vh;
    z-index: 1;
}

.optionPopupMemberBtnContainer>img {
    width: 130% !important;
    margin-right: -0.5vw;
}

.optionPopupDisconectBtnContainer>img {
    margin-right: -0.5vw;
}

.optionPopupLeavueHubBtnContainer>img {
    width: 100% !important;
    margin-right: -0.5vw;
}

.optionPopupFeedbackBtnContainer>img {
    width: 80% !important;
    margin-right: -0.5vw;
}

.feedBackForChrisBtn {
    grid-template-columns: unset;
}

@media only screen and (max-width: 600px) {
    .optionPopup {
        width: 90vw;
        height: 35vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .optionPopup>.primaryBtn {
        max-width: 40%;
        height: 4vh;
    }

    .optionPopup>.primaryBtn>label {
        font-size: calc(0.8rem + 0.6vw);
    }

    .optionPopup>.primaryBtn>img {
        margin-right: -4vw;
    }

}