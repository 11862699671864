body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}






body::-webkit-scrollbar {
  display: none;
  width: 0 !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-thin";
  src: url("./fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-bold";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-medium";
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}