.hubInviteForm {
    width: 20vw;
    max-height: 40vh;
    display: grid;
    margin-left: 40vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 1vh;
    z-index: 1;
}


.hubInviteFormParticipantContainer {
    color: #A0A5C8;
    font-size: calc(0.5rem + 0.5vw);
    max-height: 15vh;
    overflow: auto;
}

.hubInviteFormValidateBtnContainer>img,
.hubInviteFormAddBtnContainer>img {
    justify-self: end;
    margin-right: -0.5vw;
}


@media only screen and (max-width: 600px) {
    .hubInviteForm {
        width: 90vw;
        height: 50vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .hubInviteFormTopContainer {
        grid-template-columns: 0.2fr 1fr 0.2fr;
    }

    .hubInviteFormTopContainer>img {
        width: 30%;
    }

    .inputContainer>label {
        font-size: calc(1rem + 0.8vw);
    }

    .inputContainer>input {
        padding-left: 4vw;
    }

    .hubInviteFormAddBtnContainer,
    .hubInviteFormValidateBtnContainer,
    .hubInviteFormCopyBtnContainer {
        max-width: 40%;
    }

    .hubInviteFormAddBtnContainer>label,
    .hubInviteFormValidateBtnContainer>label,
    .hubInviteFormCopyBtnContainer>label {
        font-size: calc(0.8rem + 0.6vw) !important;
    }

    .hubInviteFormAddBtnContainer>img,
    .hubInviteFormValidateBtnContainer>img,
    .hubInviteFormCopyBtnContainer>img {
        margin-right: -4vw;
    }


}