.homePageTuto {
    width: 25vw;
    display: grid;
    margin-left: 36.5vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    z-index: 1;
    padding: 2vw
}

.homePageTutoParagraphe {
    width: 90%;
}

.homePage {
    width: 100%;
    height: 101vh;
    overflow-x: hidden;
}

.homePageTopContainer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 0.5fr 1fr 1fr;
    align-items: center;
    margin-top: 10vh;
}

.homePageTopContainerLeisureTimeLogo {
    width: 20%;
    justify-self: center;
}

.homePageTopContainerTitle {
    padding: 0;
    font-family: 'Roboto-bold';
    font-size: calc(1rem + 0.5vw);
    color: #393452;
    margin: 0.5rem 0;
    text-align: left;
    margin-left: -6vw;
}

.homePageTopContainerCarouselContainer {
    width: 100%;
    justify-self: end;
    cursor: pointer;
}

.homePageHubCarousel {
    border-right: 2px #D8DAE1 solid;
    justify-self: center;
    cursor: pointer;
}

.homePageTopContainerCarouselContainerHr {
    margin-bottom: -1vh;
    width: 10%;
    margin-left: 45%;
    border: 1px #4D66F3 solid;
    cursor: pointer;
}

.homePageHubCarouselItem {
    color: #C4C9DF;
    font-family: 'Roboto-bold';
    cursor: pointer;
}

.homePageHubCarouselItemLabel {
    height: 5vh;
    cursor: pointer;
}

.homePageTopContainerNouveauHubBtnContainer {
    justify-self: center;
}

.homePageTopContainerInviteFriendContainer {
    justify-self: center;
}

.homePageTopContainerUser {
    justify-self: center;
}

.primaryBtn {
    max-width: 7vw;
    background-color: #4D66F3;
    color: #FFF;
    height: 3vh;
    display: grid;
    border-radius: 3vh;
    grid-auto-flow: column;
    grid-template-columns: 0.2fr 1fr;
    align-items: center;
    border: 1px solid;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.primaryBtn>img {
    width: 70%;
    pointer-events: none;
    justify-self: center;
}

.primaryBtn>label {
    width: 100%;
    font-size: calc(0.3rem + 0.45vw);
    padding: 0;
    pointer-events: none;
    justify-self: start;
}

.homePageTopContainerInviteFriendContainerFriendLogo {
    width: 80% !important;
}

.slide.selected>.homePageHubCarouselItem {
    color: #393452;
}

.homePageTopContainerUser {
    width: 50%;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.homePageTopContainerUserProfilePicture {

    width: 2.5vw;
    border: 1px #4D66F3 solid;
    border-radius: 2.5vw;
}

.homePageTopContainerUserParagraphe {
    font-size: calc(0.4rem + 0.3vw);
    color: #393452;
    font-family: 'Roboto-bold';
}


/*----HOMEPAGE-----*/

.homePageLeisureCarouselContainer {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 0.2fr 2fr 0.2fr;
    margin-top: 5vh;
    justify-items: center;
}

.homePageLeisureCarousel {
    width: 100%;
    overflow-x: auto !important;
}

.homePageLeisureCarousel1Category {
    width: 30%;
    overflow-x: auto !important;
}

.homePageLeisureCarousel2Category {
    width: 60%;
    overflow-x: auto !important;
}

.homePageCarouselCategoryContainer {
    max-height: 60vh;
    display: grid;
}

.homePageCarouselCategoryLabelContainer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 0.05fr 1fr;
    align-items: center;
    width: 90%;
    justify-self: center;
}

.homePageCarouselCategoryLabelContainerDot {
    height: 1.5vh;
    width: 1.5vh;
    border-radius: 1.5vh;
    justify-self: start;
    margin-left: 0.5vw;
}

.homePageCarouselCategoryLabelContainerLabel {
    justify-self: start;
    color: #393452;
    font-family: "Roboto-medium";
    font-size: calc(0.5rem + 0.5vw);
    margin-left: 0.5vw;
}

.homePageCarouselCategoryList {}

.homePageCarouselCategoryElements {
    display: grid;
    justify-items: center;
    overflow-y: scroll;
    max-height: 60vh;
    padding-bottom: 3vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.homePageCarouselCategoryElements::-webkit-scrollbar {
    display: none;
    width: 0 !important
}

.caterogyRandomBtn {
    width: 3vw;
    height: 3vw;
    margin-top: 1vh;
    border-radius: 3vw;
    display: grid !important;
    align-items: center !important;
    justify-items: center !important;
}

.categoryRandomLogo {
    width: 80% !important
}

.homepageHr {
    background-color: #E0E3EB;
    border: none;
    height: 1px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 80vw;
    margin-left: 10vw;
}

.homePageLeisurActionContainer {
    width: 20vw;
    display: grid;
    grid-auto-flow: column;
    margin-left: 40vw;
    align-items: center;
    justify-items: center;
}

.homePageLeisurActionContainerRandomLeisure>img {
    width: 100%;
}

.hideNavArrow {
    opacity: 0;
    pointer-events: none;
}

.showNavArrow {
    opacity: 1;
    pointer-events: all;
}

.carouselDirectionBtn {
    width: 3vw;
    height: 3vw;
    border-radius: 3vw;
    background-color: #4D66F3;
    display: grid;
    justify-items: center;
    align-items: center;
}

.carouselDirectionBtn>img {
    width: 100%;
}

.homepageCarouselPrevBtn {
    rotate: -180deg;
    justify-self: end;
    margin-right: 1vw;
}

.homepageCarouselNextBtn {
    justify-self: start;
    margin-left: 1vw;
}

.alert {
    width: 20vw;
    left: 40vw;
    top: 45vh;
    position: absolute;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    z-index: 2;
}

@media only screen and (max-width: 600px) {


    .homePageTopContainer {
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-flow: unset;
        grid-row-gap: 1.5ch;
        margin-top: 4vh;
    }

    .homePageTopContainerLeisureTimeLogo {
        grid-column: 1;
        grid-row: 1;
        width: 50%;
    }

    .homePageTopContainerTitle {
        grid-column: 2;
        grid-row: 1;

    }

    .homePageTopContainerCarouselContainer {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .homePageHubCarousel {
        border: none;
    }

    .carouselDirectionBtn {
        width: 6vw;
        height: 6vw;
        border-radius: 6vw;
    }

    .homePageTopContainerNouveauHubBtnContainer {
        grid-row: 3;
        grid-column: 1;
        max-width: unset;
        width: 25vw !important;
        margin-right: -40vw;
        margin-top: 1vh;
    }

    .homePageTopContainerNouveauHubBtnContainer>label {
        font-size: calc(0.5rem + 0.3vw);
    }

    .homePageTopContainerNouveauHubBtnContainer>img {
        width: 80%;
        margin-right: -1vw;
    }

    .homePageTopContainerInviteFriendContainer {
        grid-row: 3;
        grid-column: 3;
        max-width: unset;
        width: 25vw !important;
        margin-left: -40vw;
        margin-top: 1vh;
    }

    .homePageTopContainerInviteFriendContainer>label {
        font-size: calc(0.5rem + 0.3vw);
    }

    .homePageTopContainerInviteFriendContainer>img {
        width: 80%;
        margin-right: -1vw;
    }

    .homePageTopContainerUser {
        grid-column: 3;
        grid-row: 1;
        display: grid;
        grid-auto-flow: unset;
        align-items: center;
        justify-items: center;
        align-self: center;
        grid-row-gap: 0.5ch;
    }

    .homePageTopContainerUser>img {
        width: 70%;
        border-radius: 7vw;
    }

    .homePageTopContainerUser>label {
        font-size: calc(0.6rem + 0.3vw);
    }

    .homePageCarouselCategoryContainer.homePageCarouselCategoryElements {
        max-height: 50vh;
    }

    .homePageCarouselCategoryLabelContainer {
        grid-template-columns: 0.1fr 1fr;
    }

    .homePageCarouselCategoryLabelContainer>label {
        font-size: calc(1rem + 0.5vw);
    }

    .caterogyRandomBtn {
        width: 10vw;
        height: 10vw;
        border-radius: 10vw;
    }

    .homePageLeisurActionContainer {
        width: 80vw;
        margin-left: 10vw;
        padding-bottom: 2vh;
    }

    .homePageLeisurActionContainer>.primaryBtn {
        max-width: unset;
        width: 25vw;
        font-size: calc(0.5rem + 0.3vw);
    }

    .homePageLeisurActionContainer>.primaryBtn>label {
        font-size: calc(0.5rem + 0.3vw);
    }

    .homePageLeisurActionContainer>.primaryBtn>img {
        margin-right: -1vw;
        width: 80%;
    }

    .alert {
        top: 75vh;
        position: absolute;
        max-width: unset;
        width: 60vw;
        left: 20vw;
    }

    .homePageTuto {
        width: 60vw;
        margin-left: 19vw;
    }

}