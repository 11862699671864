.membersPopup {
    width: 40vw;
    max-height: 50vh;
    display: grid;
    margin-left: 30vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 2vh;
    z-index: 1;
}

.membersPopupTopContainer>img {
    width: 10% !important;
}

.membersPopupUsersContainer {
    width: 80%;
    max-height: 20vh;
}

@media only screen and (max-width: 600px) {
    .membersPopup {
        width: 90vw;
        height: 35vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }


}