.newLeisureForm {
    width: 20vw;
    display: grid;
    margin-left: 40vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 1;
    grid-row-gap: 1ch;
    padding-bottom: 1vh;
}

.popupTopContainer {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.3fr;
    align-items: center;
}

.popupTopContainer>label {
    grid-column: 2;
    color: #393452;
    font-family: "Roboto-medium";
    font-size: calc(0.8rem + 0.5vw);
    margin: 1em auto;
}

.popupTopContainer>img {
    width: 15%;
    grid-column: 3;
    justify-self: center;
    justify-self: end;
    margin-right: 1vw;
}

.inputContainer {
    display: grid;
    width: 80%;
}

.inputContainer>label {
    justify-self: start;
    font-size: calc(0.5rem + 0.5vw);
}

.inputContainer>input {
    border: 1px #4D66F3 solid;
    height: 3.5vh;
    border-radius: 3.5vh;
    margin-top: 1vh;
    padding-left: 1vw;
}

.newLeisureCategorySelectContainerSelect {
    border: 1px solid;
    margin-top: 1vh;
    height: 3.7vh;
    border: 1px #4D66F3 solid;
    padding-left: 2vw;
    padding-right: 1vw;
    border-radius: 3.5vh;
    appearance: none;
    background-image: url("../assets/arrow field purple.png");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.5em;
}

.newLeisureFormCloseBtn {
    justify-self: end !important;
    margin-right: -1vw;
}

.newLeisureFormAddDescriptionBtnContainer {}

.newLeisureFormAddUrlBtnContainer {}

@media only screen and (max-width: 600px) {
    .newLeisureForm {
        width: 90vw;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .popupTopContainer>label {
        font-size: calc(1.5rem + 0.5vw);
    }

    .popupTopContainer>img {
        justify-self: center;
    }

    .newLeisureFormValidateBtnContainer {
        max-width: 40%;
    }

    .newLeisureFormValidateBtnContainer>label {
        font-size: calc(0.8rem + 0.5vw);
    }

    .newLeisureFormValidateBtnContainer>img {
        margin-right: -4vw;
    }
}