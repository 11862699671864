.container {
    padding: 5vh 5vw;
}

.header {
    font-weight: bold;
}

.paragraph {}

.list {
    padding-left: 40px;
}

.listItem {
    margin-bottom: 10px;
}