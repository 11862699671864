@keyframes ldio-fxb0oxrj504 {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55)
    }

    0% {
        transform: translate(0, 0)
    }

    50% {
        transform: translate(0, 108px);
        animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
    }

    100% {
        transform: translate(0, 0);
    }
}

.ldio-fxb0oxrj504 div {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #4d66f3;
    left: 74px;
    top: 20px;
    animation: ldio-fxb0oxrj504 1s linear infinite;
}

.loadingio-spinner-ball-3la1xs3rad6 {
    width: 50vw;
    height: 30vh;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 25vw;
}

.ldio-fxb0oxrj504 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-fxb0oxrj504 div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */