.loginContainer {
    width: 80vw;
    margin-left: 10vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    /*height: 80vh;*/
}

.loginLeftPart {
    grid-column: 1;
    color: #FFF;
    background-color: #4D66F3;
    padding-top: 10vh;
}

.loginLeftPartTile {
    font-family: "Roboto-bold";
    margin-top: 10%;
    font-size: calc(1rem + 2vw);
}

.loginLeftPartDescription {
    font-size: calc(1rem + 0.8vw);
}

.loginLeftPartHumains {
    width: 100%;
}

/*--RIGHT PART Inscription--*/

.loginRightPart {
    grid-column: 2;
    background-color: #FFF;
    display: grid;
    justify-items: center;
    grid-auto-rows: auto;
}

.loginRightPartLeisureTimeLogo {
    width: 12%;
    align-self: end;
}


.loginRightPartDescription {
    font-size: calc(1rem + 0.8vw);
    width: 60%;
    align-self: center;
}

.loginRightPartContainer {
    display: grid;
    justify-items: center;
    margin-bottom: 5vh;
}

.loginRightPartConnexionTogleContainer {
    display: grid;
    width: 60%;
    height: 4vh;
    align-self: center;
}

.loginRightPartConnexionBtn {
    grid-column: 1;
    color: #4D66F3;
    background-color: #FFF;
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    font-size: calc(1rem + 0.8vw);
    font-family: "Roboto-medium";
}

.loginRightPartInscriptionBtn {
    grid-column: 2;
    color: #4D66F3;
    background-color: #FFF;
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    font-size: calc(1rem + 0.8vw);
    font-family: "Roboto-medium";
}


.hover:hover {
    cursor: pointer;
}

.loginRightPartForm>.inputContainer {
    width: 100% !important;
}

.loginRightPartForm>.inputContainer>input {
    max-height: 4vh;
}



.inputContainer {
    display: grid;
}

.inputContainerParagraphe {
    justify-self: start;
    font-size: calc(1rem + 0.5vw);
    margin: 0;
    align-self: center;
}

.inputContainerInput {
    height: 4vh;
    border: 1px solid #4D66F3;
    border-radius: 2vh;
    font-size: calc(1rem + 0.5vw);
    padding-left: 2vw;
}

.loginWithGoogleContainer {
    border: 1px #D9D9D9 solid;
    display: grid;
    width: 60%;
    height: 4vh;
    border-radius: 2vh;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    justify-items: center;
    align-self: center;

}

.loginWithGoogleContainerLogo {
    grid-column: 1;
    width: 25%;
    justify-self: end;
    align-self: center;
}

.loginWithGoogleContainerParagraphe {
    grid-column: 2;
    font-size: calc(0.6rem + 0.5vw);
    color: #393452;
    width: 100%;
    font-family: "Roboto-medium";
}

.loginRightPartSignUpSubmitContainer {
    color: white;
}

.loginRightPartLoginSubmitContainerParagraphe {
    color: white;
}

/*--RIGHT PART Connexion--*/

.loginRightPartForm {
    width: 60%;
    display: grid;
    row-gap: 1ch;
    /*height: -webkit-fill-available;*/
    align-self: start;
}

.loginRightPartFormSubmit {
    color: #FFF;
    background-color: #4D66F3;
    font-size: calc(1rem + 0.5vw);
    border: 0;
    font-family: "Roboto-medium";
    width: 100%;
    padding: 0;
}

.loginRightPartTopStain {
    display: none;
}

.loginRightPartBottomStain {
    display: none;
}

@media only screen and (max-width: 600px) {

    .loginContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        width: 100vw;
        margin: 0;
    }

    .loginLeftPart {
        display: none;
    }

    .loginRightPart {
        grid-column: 1;
        height: 90vh;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-top: 3vh;
        padding-bottom: 7vh;
    }

    .loginRightPartContainer {
        margin-bottom: 0;
    }


    .loginRightPartLeisureTimeLogo {
        width: 25%;
        margin-top: -5vh;
    }

    .loginRightPartForm {
        width: 80%;
    }

    .loginRightPartConnexionTogleContainer {
        width: 80%;
    }

    .loginWithGoogleContainer {
        width: 80%;
        margin-top: 2vh;
    }


    .loginWithGoogleContainerParagraphe {
        /*font-size: calc(1rem + 0.5vw);*/
        font-size: 15px;
    }

    .loginRightPartTopStain {
        display: block;
        width: 50%;
        margin-left: 80vw;
        margin-top: -10vh;
    }

    .loginRightPartBottomStain {
        display: block;
        width: 50%;
        rotate: 180deg;
        margin-right: 80vw;
        margin-bottom: -15vh;
    }

    .loginRightPartFormSubmit {
        font-size: 15px;
    }

}