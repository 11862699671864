.indexPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    width: 100%;

    h1 {
        color: #181818;
        margin: 0;
        font-size: clamp(1.5rem, 5rem, 2.5rem)
    }

    >p {
        color: #808080;
        margin: 0;
        width: 50%;
        text-align: center;
        margin-top: 2.5vh;
    }
}

.downloadButtonContainer {
    margin-top: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;


    a {
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
        padding: 12px 16px;
        background-color: #4D66F3;
        border-radius: 5px;


        >p {
            color: white;
            margin: 0;

        }

        >img {
            width: 30px;
        }
    }
}

.appScreenshots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5vh;
    overflow: hidden;
    padding: 1vh;

    :nth-child(1) {
        width: 25vh;
    }

    :nth-child(2) {
        width: 35vh;
    }

    :nth-child(3) {
        width: 25vh;
    }
}

.circleBackground {
    position: fixed;
    z-index: -1;
    bottom: 0;
}

@media (max-width: 768px) {
    .indexPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5vh;
        width: 100%;

        h1 {
            color: #181818;
            margin: 0;
            text-align: center;
            font-size: clamp(1.5rem, 5rem, 2.5rem)
        }

        >p {
            color: #808080;
            margin: 0;
            width: 90%;
            text-align: center;
            margin-top: 2.5vh;
        }
    }

    .downloadButtonContainer {
        margin-top: 1vh;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10vw;
        margin-top: 2.5vh;
        margin-bottom: 2.5vh;

        a {
            cursor: pointer;
            gap: 0.5vw;
            padding: 1.5vh 2.5vw;
            width: 30vw;
            height: 2.5vh;

            background-color: #4D66F3;
            border-radius: 5px;

            >p {
                color: white;
                margin: 0;
                width: 100%;
                white-space: nowrap;
            }

            >img {
                width: 30px;
            }
        }
    }

    .appScreenshots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;

        :nth-child(1) {
            width: 25vw;
        }

        :nth-child(2) {
            width: 50vw;
        }

        :nth-child(3) {
            width: 25vw;
        }
    }
}