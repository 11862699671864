.popupDarkBackgroung {
    position: absolute;
    height: 107vh;
    width: 100vw;
    top: 0;
    background-color: black;
    opacity: 0.2;
}

.confirmationPopup {
    width: 25vw;
    max-height: 20vh;
    display: grid;
    margin-left: 37.5vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 1vh;
    z-index: 1;
}

.confirmationPopupTile {
    color: #393452;
    font-family: 'Roboto-medium';
    font-size: calc(0.8rem + 0.5vw);
}

.confirmationPopupDescription {
    color: #C7C7C7;
    font-size: calc(0.5rem + 0.5vw);
    width: 90%;
    max-height: 10vh;
    overflow-wrap: break-word;
    overflow: auto;
}

.confirmationPopupBtnContainer {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    width: 100%;
}

.secondaryBtn {
    max-width: 7vw;
    background-color: #FFF;
    border: 1px #4D66F3 solid;
    color: #FFF;
    height: 3vh;
    display: grid;
    border-radius: 3vh;
    grid-auto-flow: column;
    grid-template-columns: 0.2fr 1fr;
    align-items: center;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.secondaryBtn>label {
    width: 100%;
    font-size: calc(0.3rem + 0.45vw);
    padding: 0;
    pointer-events: none;
    justify-self: start;
    color: #4D66F3;
}

.secondaryBtn>img,
.confirmationPopupBtnContainerYesBtn>img {
    width: 70%;
    pointer-events: none;
    justify-self: end;
    margin-right: -0.5vw;
}

@media only screen and (max-width: 600px) {
    .confirmationPopup {
        width: 90vw;
        height: 35vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .confirmationPopupTile {
        font-size: calc(1.5rem + 0.5vw);
    }

    .confirmationPopupDescription {
        font-size: calc(0.7rem + 0.5vw);
    }

    .secondaryBtn {
        max-width: unset;
        max-width: 40%;
    }

    .secondaryBtn>img {
        margin-right: -4vw
    }

    .secondaryBtn>label {
        font-size: calc(0.8rem + 0.5vw);
    }


    .confirmationPopupBtnContainer>.secondaryBtn {
        max-width: 60%;
    }

    .confirmationPopupBtnContainer>.secondaryBtn>label {
        font-size: calc(0.8rem + 0.5vw);
    }

    .confirmationPopupBtnContainer>.primaryBtn {
        max-width: 60%;
    }

    .confirmationPopupBtnContainer>.primaryBtn>label {
        font-size: calc(0.8rem + 0.5vw);
    }

}