.notFoundPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.notFoundImgContainer {
    max-width: 100%;
    max-height: 100%;
}