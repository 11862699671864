.user {
    display: grid;
    height: 5vh;
}

.userName {
    grid-column: 1;
    height: auto;
    color: #C2C6CC;
    grid-row: 1;
    align-self: end;
    justify-self: end;
    font-size: calc(0.8rem + 0.2vw);
}

.userMail {
    grid-column: 1;
    height: auto;
    color: #C2C6CC;
    grid-row: 2;
    justify-self: end;
    font-size: calc(0.7rem + 0.2vw);
}

.userPicture {
    grid-column: 2;
    align-self: center;
    width: 4vh;
    border-radius: 4vh;
    border: 2px #4D66F3 solid;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-left: 2vw;
}

.userPictureAdmin {
    grid-column: 2;
    align-self: center;
    width: 4vh;
    border-radius: 4vh;
    border: 2px #22db59 solid;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-left: 2vw;
}

.userDeleteBtn {
    width: 3vh;
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
    justify-self: center;
}

.userRemoveConfirmationContainer {
    z-index: 1;
    position: absolute;
    width: 85vw;
    left: 1vw;
    background-color: #F7F8FA;
    border: 2px #4D66F3 solid;
    color: #4D66F3;
    display: grid;
    top: 25vh;
    row-gap: 1vh;
    padding: 1vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.userRemoveConfirmationContainerLabel {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    font-weight: bold;
    font-size: calc(1rem + 0.2vw);
}

.userRemoveConfirmationContainerYesBtn {
    border: none;
    color: #fff;
    background-color: #4D66F3;
    padding: auto;
    font-size: calc(1rem + 0.2vw);
    font-weight: bold;
    max-height: 3vh;
    width: 15vw;
    justify-self: center;
}

.userRemoveConfirmationContainerNoBtn {
    border: none;
    color: #fff;
    background-color: #4D66F3;
    padding: auto;
    font-size: calc(1rem + 0.2vw);
    font-weight: bold;
    max-height: 3vh;
    width: 15vw;
    justify-self: center;
}

@media only screen and (max-width: 600px) {
    .user {

        height: 8vh;
    }

    .userPopupuUserContainer {
        max-height: 25vh;
    }

    .userPicture {
        width: 6vh;
        border-radius: 6vh;
    }

    .userPictureAdmin {
        width: 6vh;
        border-radius: 6vh;
    }

}