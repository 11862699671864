ul {
    list-style-type: none;
    padding: 0;
}

.leisure {
    width: 90%;
    height: 10vh;
    background-color: #fff;
    border-radius: 1vh;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 0.1fr 1.5fr;
    margin-top: 1vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 1;
}

.leisureLeftBar {
    width: 0.15vw;
    height: 8vh;
    margin-left: 0.5vw;
    justify-self: start;
}

.leisureRightSide {
    justify-self: left;
    display: grid;
    width: 95%;
    overflow: hidden;
    height: 10vh;
    align-items: center;
    justify-items: start;
}

.leisureRightSideDescription {
    font-size: calc(1rem + 0.2vw);
    font-family: 'Roboto-medium';
}

.leisureRightSideCreator {
    color: #C2C6CC;
    font-size: calc(0.5rem + 0.4vw);
}

.leisureRightSideCreationDate {
    color: #C2C6CC;
    font-size: calc(0.5rem + 0.4vw);
    padding-bottom: 0.5vh
}

@media only screen and (max-width: 600px) {

    .leisureLeftBar {
        width: 0.5vw;
        justify-self: center;
    }

    .leisureRightSideCreator {
        font-size: calc(0.7rem + 0.6vw);
    }

    .leisureRightSideCreationDate {
        font-size: calc(0.6rem + 0.5vw);
    }
}