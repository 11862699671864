.beugsAemeliorationPopup {
    width: 20vw;
    max-height: 40vh;
    display: grid;
    margin-left: 40vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 1vh;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .beugsAemeliorationPopup {
        width: 90vw;
        height: 35vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .beugsAemeliorationPopupFormTopContainer {
        grid-template-columns: 0.2fr 1fr 0.2fr;
    }

    .beugsAemeliorationPopupFormTopContainer>img {
        width: 30%;
    }

    .beugsAemeliorationPopup>.primaryBtn>label {
        font-size: calc(0.8rem + 0.6vw);
    }

}