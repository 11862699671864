.newHubForm {
    width: 20vw;
    max-height: 50vh;
    display: grid;
    margin-left: 40vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 1vh;
    z-index: 1;
}

.newHubFormParticipantContainer {
    font-size: calc(0.5rem + 0.5vw);
    max-height: 15vh;
    overflow: auto;
}


@media only screen and (max-width: 600px) {
    .newHubForm {
        width: 90vw;
        height: 50vh;
        margin-left: 5vw;
    }

    .newHubForm>.primaryBtn {
        max-width: 40%;
    }

    .newHubForm>.primaryBtn>label {
        font-size: calc(0.8rem + 0.6vw);
    }

    .newHubForm>.primaryBtn>img {
        margin-right: -4vw;
    }



}