.circlesBackground {
    overflow: hidden;
    bottom: 0;

    :nth-child(1) {
        --size: 10vw;
        width: var(--size);
        height: var(--size);
        bottom: calc(var(--size) / -2);
        border: 4px solid #b8c2fa;
    }

    :nth-child(2) {
        --size: 20vw;
        width: var(--size);
        height: var(--size);
        bottom: calc(var(--size) / -2);
        border: 4px solid #cad1fb;
    }

    :nth-child(3) {
        --size: 30vw;
        width: var(--size);
        height: var(--size);
        bottom: calc(var(--size) / -2);
        border: 4px solid #dbe0fd;
    }

    :nth-child(4) {
        --size: 40vw;
        width: var(--size);
        height: var(--size);
        bottom: calc(var(--size) / -2);
        border: 4px solid #dbe0fd;
    }

    :nth-child(5) {
        --size: 50vw;
        width: var(--size);
        height: var(--size);
        bottom: calc(var(--size) / -2);
        border: 4px solid #edf0fe;
    }
}

.circle {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    border-radius: 50%;
}