.leisureExtend {
    width: 40vw;
    max-height: 60vh;
    display: grid;
    margin-left: 30vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding-bottom: 2vh;
    z-index: 1;
}

.leisureExtendTopContainer>img {
    width: 8% !important;
    margin-top: 1vh;
}

.leisureExtendTopContainer>span {
    color: #C7C7C7;
    font-size: calc(0.5rem + 0.5vw);
}

.leisureExtendedLeisureTitle {
    width: 90%;
    max-height: 10vh;
    overflow-wrap: break-word;
    overflow: auto;
}

.leisureExtendedLeisureDescription {
    margin: 0;
    color: #C7C7C7;
    width: 90%;
    max-height: 10vh;
    font-size: calc(0.5rem + 0.5vw);
    overflow-wrap: break-word;
    overflow: auto;
}

.leisureExtendedLeisureUrl {
    width: 90%;
    max-height: 10vh;
    font-size: calc(0.5rem + 0.5vw);
    overflow-wrap: break-word;
    overflow: auto;
}

.leisureExtendedBtnContainer {
    display: grid;
    grid-auto-flow: column;
    width: 80%;
    justify-items: center;
}

.leisureExtendedLeisureCreator {
    color: #C7C7C7;
    font-size: calc(0.5rem + 0.5vw);
}

.leisureExtendedLeisureUrl {
    font-size: calc(0.5rem + 0.5vw);
    justify-self: center;
}

.leisureExtendedBtnContainer>div>img {
    margin-right: -1vw !important;
}

.leisureExtendedDoneDate {
    color: #4D66F3;
}

.leisureExtendedInfoContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: calc(0.5rem + 0.5vw);
    color: #C7C7C7;
}

.leisureExtendedInfoContainer>span {
    grid-column: 3;
}


@media only screen and (max-width: 600px) {
    .leisureExtend {
        width: 90vw;
        max-height: 40vh;
        margin-left: 5vw;
        background-color: #FFF;
        border-radius: 0.5vh;
    }

    .leisureExtendTopContainer>img {
        width: 15% !important;
    }

    .leisureExtendTopContainer>span {
        font-size: calc(0.6rem + 0.6vw);
    }

    .leisureExtendedLeisureTitle {
        font-size: calc(0.8rem + 0.8vw);
    }

    .leisureExtendedLeisureDescription {
        font-size: calc(0.8rem + 0.6vw);
    }

    .leisureExtendedLeisureUrl {
        font-size: calc(0.8rem + 0.6vw);
    }

    .leisureExtendedInfoContainer {
        font-size: calc(0.7rem + 0.7vw);
    }

    .leisureExtend>.leisureExtendedBtnContainer>.primaryBtn {
        max-width: 90%;
    }

    .leisureExtend>.leisureExtendedBtnContainer>.primaryBtn>label {
        font-size: calc(0.7rem + 0.5vw);
    }

    .leisureExtend>.leisureExtendedBtnContainer>.primaryBtn>img {
        width: 100%;
        margin-right: -2vw !important;
    }
}