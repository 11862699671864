.feedbackForChris {
    width: 80vw;
    max-height: 50vh;
    display: grid;
    margin-left: 8vw;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    background-color: #FFF;
    align-items: center;
    justify-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    row-gap: 1ch;
    padding: 2vh;
    z-index: 1;
    overflow: auto;
}

.feedBackContainer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    align-items: center;
    width: 100%;
    grid-column-gap: 0.5ch;
    border-top: 1px solid;
}

.feedBackDate {}

.feedBackDevice {}

.feedBackparagraphe {
    margin: 0;
}